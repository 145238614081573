import React, { Component } from 'react';
import logo from '../assets/img/logo.svg';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default function footer(props) {
  const menu1 = [
    {
      id: 1,
      name: 'support@activia14daychallenge.com',
      link: 'mailto:support@activia14daychallenge.com',
      target: false,
    },
    { id: 2, name: '800 124 1573', link: 'tel:+78001241573', target: false },
    { id: 3, name: 'Leave us a message', link: '#', target: false },
  ];
  const menu2 = [
    { id: 1, name: 'Our Products', link: '/#product', target: false },
    { id: 2, name: 'Recipes', link: '#', target: false },
    { id: 3, name: 'Health tips', link: '#', target: false },
  ];
  return (
    <div className="footer">
      <div className="container d-flex d-f-column">
        <div className="footer-block footer-block-top">
          <div className="footer-1">
            <a href="/" className="logo">
              <img src={logo} className="nav-logo" alt="Logo" />
            </a>
            <div className="social">
              <a href="#" target="_blank" className="ico ico-vk"></a>
              <a href="#" target="_blank" className="ico ico-ok"></a>
              <a href="#" target="_blank" className="ico ico-ic1"></a>
              <a href="#" target="_blank" className="ico ico-you2"></a>
            </div>
          </div>
          <div className="footer-2">
            <a className="footer-phone" href="tel:88001009969">
              8 (800) 100-99-69
            </a>
            <p>
              Бесплатная горячая линия
              <br />С 6:00 до 22:00 по МСК
            </p>
            <a className="question" onClick={() => props.runModalShow(0, 'feedack')}>
              Задайте нам вопрос
            </a>
          </div>
          <div className="footer-3">
            <div className="footer-info">
              <div className="footer-info-title">Грудное молоко — лучшее питание для малыша</div>
              <div className="footer-info-text">
                Всемирная Организация Здравоохранения рекомендует грудное вскармливание для детей
                до 6 месяцев. «Nutrilak» поддерживает ВОЗ и предлагает продолжать грудное
                вскармливание как можно дольше. Перед применением продукта проконсультируйтесь
                со специалистом
              </div>
            </div>
          </div>
        </div>
        <div className="footer-block footer-block-2">
          <div className="footer-1">
            <div className="copy">© 2009−2022 АО «ИНФАПРИМ»</div>
          </div>
          <div className="footer-2 menu menu-contact">
            <ul className="footer-menu">
              <li>
                <a href="#">Политика защиты и обработки персональных данных</a>
              </li>
              <li>
                <a href="#">Положение о порядке хранения и защиты персональных данных</a>
              </li>
            </ul>
          </div>
          <div className="footer-3 menu menu-contact">
            <ul className="footer-menu">
              <li>
                <a href="#">Согласие посетителя сайта на обработку персональных данных</a>
              </li>
              <li>
                <a href="#">Условия отбора контрагентов</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
