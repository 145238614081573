import React, { Component } from 'react';
import logo from '../assets/img/logo.svg';
import logoWhite from '../assets/img/logoWhite.svg';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      BodyClass: '',
      tab: 0,
      cabinet: false,
    };
  }

  componentDidMount() {}
  render() {
    return (
      <div
        className={
          'header header_min ' +
          (!this.props.directionDown && this.props.scroll > 5 ? 'top ' : '') +
          (this.props.nav === true ? 'active' : '')
        }>
        <div className="container">
          <div className="logo__box d-flex align-items-center">
            <a href="/" className="logo">
              <img src={this.props.nav ? logoWhite : logo} className="nav-logo" alt="Logo" />
            </a>
          </div>
          <div className="header__btn">
            <div className={'hamburger ' + (this.props.nav ? ' -active' : '')}>
              <div
                className="hamburger-box"
                onClick={() => {
                  this.props.clickMenu();
                }}>
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
          <div className={'header__right' + (this.props.nav ? ' active' : '')}>
            <div className="container-m">
              <ul id="menu">
                {this.props.menu?.map((mn, index) => (
                  <li key={mn.link + '_' + index}>
                    <a
                      href={mn.link}
                      onClick={() => {
                        this.props.clickMenuClose();
                      }}>
                      {mn.name}
                    </a>
                  </li>
                ))}
              </ul>
              {localStorage.getItem('auth_key') ? (
                <a href="/cabinet" className="btn btn-2 btn-sm">
                  Личный кабинет
                </a>
              ) : (
                <button
                  className="btn btn-2 btn-sm"
                  onClick={() => this.props.runModalShow(1, 'registr')}>
                  Личный кабинет
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
