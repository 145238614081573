import React from 'react';
import CustomScroll from 'react-custom-scroll';

// require('es6-promise').polyfill();
// require('isomorphic-fetch');

export default class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: 2,
      faqList: [
        {
          id: 1,
          title: 'Как принять участие в акции?',
          text: 'Организатором Акции является общество с&nbsp;ограниченной ответственностью &laquo;Едадил Промо&raquo; (далее&nbsp;&mdash; &laquo;Организатор&raquo;). Адрес местонахождения: Россия, 119021, Москва, ул. Тимура Фрунзе, дом&nbsp;11, корпус&nbsp;2, этаж&nbsp;3, помещение&nbsp;1, комната 14, ИНН 7704337959',
        },
        {
          id: 2,
          title: 'Где проводится акция?',
          text: 'Акция проводится в торговых точках (онлайн и оффлайн), расположенных на территории российской федерации, в которых реализуется продукция, участвующая в акции.',
        },
        {
          id: 3,
          title: 'Сколько продлится акция?',
          text: 'Территория проведения Акции&nbsp;&mdash; Российская Федерация.',
        },
        {
          id: 4,
          title: 'Какой продукт участвует в акции?',
          text: 'Общий срок проведения Акции: с&nbsp;27.02.2023 года по&nbsp;30.06.2023 года (включительно), включая период выдачи призов Победителям. <br/> Сроки совершения Участником действий для участия в&nbsp;Акции (совершение действия для Начисления Кэшбэка: покупка Продукции, сканирование чека, Начисление Кэшбэка): с&nbsp;27.02.2023 года по&nbsp;23.04.2023 года (включительно).',
        },
        {
          id: 5,
          title: 'Как зарегистрироваться на сайте?',
          text: 'Для того, чтобы стать Участником Акции, необходимо в&nbsp;Период приема заявок для участия в&nbsp;Акции:<br/>1. Купить не&nbsp;менее одного Продукта Orbit, указанного в&nbsp;п.&nbsp;1.9 правил Акции и&nbsp;сохранить чек.<br/>2. Зарегистрироваться на&nbsp;сайте Акции или в&nbsp;сервисе Едадил.<br/>3. Загрузить кассовый чек на&nbsp;сайте Акции или в&nbsp;сервисе Едадил и&nbsp;пройти проверку чека',
        },
        {
          id: 6,
          title:
            'Что делать, если я изменил(а) e-mail? Я был зарегистрирован и хочу продолжить участие.',
          text: 'Зайдя на&nbsp;сайт Акции или через сервис Едадил в&nbsp;мобильном Приложении, необходимо навести камеру на&nbsp;QR-код в&nbsp;кассовом чеке.',
        },
        {
          id: 7,
          title: 'Как восстановить пароль?',
          text: 'Ограничений нет.',
        },
        {
          id: 8,
          title: 'Обязательна ли регистрация на сайте?',
          text: 'Участник Акции обязуется сохранять Чек, подтверждающий факт покупки Продукции. В&nbsp;случае, если у&nbsp;Организатора возникнут сомнения в&nbsp;достоверности предоставленной Участником информации или в&nbsp;соблюдении Участником Правил, наличии в&nbsp;действиях Пользователя злоупотреблений Правилами, то&nbsp;Организатор имеет право запросить у&nbsp;Участника вышеуказанные документы и&nbsp;иную информацию, а&nbsp;Участник обязуется ее&nbsp;предоставить.',
        },
        {
          id: 9,
          title: 'Как зарегистрировать чек на сайте?',
          text: 'Участник может в&nbsp;любой момент отказаться от&nbsp;участия в&nbsp;Акции, направив соответствующее заявление Организатору заказным почтовым отправлением либо скан-копию (электронный образ) подписанного заявления по&nbsp;электронной почте Организатора support@edadeal.ru. Заявление составляется в&nbsp;свободной форме и&nbsp;должно содержать сведения, позволяющие идентифицировать Участника.',
        },
        {
          id: 10,
          title: 'Не могу зарегистрировать чек на сайте. что делать?',
          text: 'В&nbsp;розничных торговых точках на&nbsp;территории Российской Федерации.',
        },
        {
          id: 11,
          title: 'Что делать, если чек не прошёл модерацию?',
          text: 'Вся продукция Orbit принимает участие в&nbsp;Акции. ',
        },
      ],
    };
  }

  componentDidMount() {}
  toggleFaq = (numb) => {
    if (this.state.faq === numb) {
      this.setState({
        faq: 0,
      });
    } else {
      this.setState({
        faq: numb,
      });
    }
  };

  render() {
    return (
      <div className="faq">
        <div className="faq__list">
          {this.state.faqList?.map((fq, index) => (
            <div
              key={index}
              onClick={() => {
                this.toggleFaq(fq.id);
              }}
              className={'faq__item ' + (this.state.faq === fq.id ? 'active' : '')}>
              <div
                className="faq__item-header"
                dangerouslySetInnerHTML={{ __html: fq.title }}></div>
              <div className={'faq__item-answer fadeIn animated'}>
                <div
                  className="faq__item-text "
                  dangerouslySetInnerHTML={{ __html: fq.text }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
