import React, { useState, useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactPaginate from 'react-paginate';
import { Redirect } from 'react-router-dom';

export default function App(props) {
  // const [props.codes, props.setprops.codes] = useState([]);
  // const [props.perPage, setprops.perPage] = useState(null);
  useEffect(() => {
    document.body.classList.add('cabinet');
    props.getCodes(1);
  }, []);

  const renderWinners = () => {
    if (props.codes.length > 0) {
      return props.codes.map((code, index) => (
        <div
          key={'winner_' + index.toString()}
          className="winner_table table__row d-flex align-items-center">
          <div className="tb__col m-flex">
            <span
              className="check-img"
              onClick={() => {
                props.runModalShow(7);
                localStorage.setItem('checkImg', code.img);
              }}></span>
            {code.id_for_user}
          </div>
          {/* <div className="tb__col d-hidden"></div> */}
          <div className="tb__col">{code.date_create}</div>
          <div className="tb__col overflow-init m-text-right d-text-left ">
            <span className={'popover overflow-init ' + code.status.type}>
              {code.status.text}
              {code.status.type === 'bad' && (
                <span className="popover-content">{code.status.rejectReason}</span>
              )}
            </span>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="table__row d-flex align-items-start">
          <div className="tb__col tb__col-empty null-check">Нет чеков</div>
        </div>
      );
    }
  };

  if (localStorage.getItem('auth_key')) {
    return (
      <div className="app">
        <div id="fullpage-wrap">
          <div className="section section-lk section-top square fp-auto-height-responsive" id="lk">
            <div className="container">
              <h1 className="text-center">Личный кабинет</h1>
              <div className="block-lk d-flex">
                <div className="block-lk-item">
                  <div className="block-lk-title">{props.profile.checksCount}</div>
                  <div
                    className="block-lk-btn_check"
                    onClick={() => {
                      document.getElementById('apm-scan-qr').click();
                      // this.props.info5Showw();
                    }}>
                    <span className="ico ico-check"></span>
                    Загрузить чек
                  </div>
                </div>
                <div className="block-lk-item">
                  <div className="block-lk-title">Личные данные</div>
                  <div className="block-lk-data">
                    <span>Имя:</span>
                    {props.profile.name}
                  </div>
                  <div className="block-lk-data">
                    <span>Email:</span>
                    {props.profile.email}
                  </div>
                  <div className="block-lk-btn d-flex">
                    <div
                      className="btn btn-2 btn-sm"
                      onClick={() => props.runModalShow(12, 'updateProfile')}>
                      Изменить
                    </div>
                    <div className="btn btn-2 btn-sm" onClick={() => props.submit('close')}>
                      Выйти
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section section-my_check section-top square fp-auto-height-responsive"
            id="my_check">
            <div className="container">
              <h2 className="section-title">Мои чеки</h2>
              <div className="border-gradient section-lk-content">
                <div className=" tab__pane-billa fadeIn animated active">
                  <div className="table__wrap">
                    <div className="table__head m-hidden">
                      <div className="table__row d-flex align-items-start">
                        <div className="tb__col tb__th">№ чека</div>
                        <div className="tb__col tb__th">Дата</div>
                        <div className="tb__col tb__th">Статус чека</div>
                      </div>
                    </div>
                    <div className="table__body" data-count={props.codes.length}>
                      <div className="table__scroll">
                        <Scrollbars heightRelativeToParent="100%">
                          <div className="table__rows">{renderWinners()}</div>
                        </Scrollbars>
                      </div>
                    </div>
                  </div>
                </div>
                {props.perPage > 1 ? (
                  <ReactPaginate
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    marginPagesDisplayed={2}
                    pageCount={props.perPage}
                    pageRangeDisplayed={5}
                    // onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    previousLabel={'‹'}
                    nextLabel={'›'}
                    activeClassName={'active'}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div
            className="section section-my_prize section-top square fp-auto-height-responsive"
            id="my_prize">
            <div className="container">
              <h2 className="section-title">Вам назначен приз</h2>
              <div className="d-text-center">
                <div className="section-title-min">
                  С правилами получения призов можно ознакомиться <a href="/#pravila">по ссылке</a>.
                </div>
              </div>
              <div className="my_prize">
                {props.profile.prize1 > 0 && (
                  <div className="my_prize-item">
                    <div className="my_prize-item-info">Ваш приз</div>
                    <div className="my_prize-item-img my_prize-item-img-1"></div>
                    <div className="my_prize-item-title">Яндекс.Музыка</div>
                    <div className="my_prize-item-text">Сертификат 299₽ Яндекс.Музыка</div>
                  </div>
                )}
                {props.profile.prize2 > 0 && (
                  <div className="my_prize-item">
                    <div className="my_prize-item-info">Ваш приз</div>
                    <div className="my_prize-item-img my_prize-item-img-2"></div>
                    <div className="my_prize-item-title">Путешествие</div>
                    <div className="my_prize-item-text">200.000₽ на путешествие</div>
                  </div>
                )}
                {props.profile.prize3 > 0 && (
                  <div className="my_prize-item">
                    <div className="my_prize-item-info">Ваш приз</div>
                    <div className="my_prize-item-img my_prize-item-img-3"></div>
                    <div className="my_prize-item-title">Деньги</div>
                    <div className="my_prize-item-text">3.000₽ на карту</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
