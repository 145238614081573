import React, { useState, useEffect } from 'react';
import Modals from './Modals';
import logo from '../assets/img/logo.svg';
import Winners from './Winners';
import Faq from './Faq';

export default function App(props) {
  useEffect(() => {
    document.body.classList.add('home');
  });

  return (
    <div className="app">
      <div id="fullpage-wrap">
        <div className="section section-1 section-main square fp-auto-height-responsive" id="main">
          <div className="container">
            <div className="content">
              <div className="content-left">
                <h1>Познавайте мир вместе с Nutrilak</h1>
                <p>Покупай продукцию Nutrilak и получай призы. Главный приз — Путешествие!</p>
                {props.btnUploadCheck('btn btn-1 btn-xl')}
              </div>
              <div className="content-right">
                <div className="main-img"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-2 square fp-auto-height-responsive" id="pravila">
          <div className="container">
            <h2 className="section-title">ПРАВИЛА АКЦИИ</h2>
            <div className="section-title-min">
              Сроки проведения акции: с 19.02.2024 по 21.04.2024
            </div>

            <div className="step">
              <div className="step-item">
                <div className="step-img step-img-1"></div>
                <div className="step-title">1. Покупайте</div>
                <div className="step-text">
                  Купите 2 любые пачки смеси Nutrilak в{' '}
                  <a href="javascript:void(0);" onClick={() => props.runModalShow(6)}>
                    магазинах-партнерах проекта
                  </a>
                  *.
                </div>
              </div>
              <div className="step-item">
                <div className="step-img step-img-2"></div>
                <div className="step-title">2. Регистрируйте</div>
                <div className="step-text">Зарегистрируйте чек на сайте акции.</div>
              </div>
              <div className="step-item">
                <div className="step-img step-img-3"></div>
                <div className="step-title">3. Получайте</div>
                <div className="step-text">Выигрывайте призы! Главный приз – путешествие.</div>
              </div>
            </div>
            <div className="d-flex d-text-center">{props.btnUploadCheck('btn btn-1 btn-lg')}</div>
          </div>
        </div>
        <div
          className="section section-3 section-prize square fp-auto-height-responsive"
          id="prize">
          <div className="wave wave-top"></div>
          <div className="wave wave-bottom"></div>
          <div className="container">
            <h2 className="section-title">Призы</h2>
            <div className="prize">
              <div className="prize-item">
                <div className="prize-img prize-img-1"></div>
                <div className="prize-block-text">
                  <div className="prize-title">Яндекс.Музыка</div>
                  <div className="prize-ball">Сертификат 299₽</div>
                  <div className="prize-text">Каждому 10-ому участнику</div>
                </div>
              </div>
              <div className="prize-item">
                <div className="prize-img prize-img-2"></div>
                <div className="prize-block-text">
                  <div className="prize-title">Деньги</div>
                  <div className="prize-ball">3 000₽ на карту</div>
                  <div className="prize-text">Еженедельный приз</div>
                </div>
              </div>
              <div className="prize-item">
                <div className="prize-img prize-img-3"></div>
                <div className="prize-block-text">
                  <div className="prize-title">Путешествие</div>
                  <div className="prize-ball">На 200 000₽</div>
                  <div className="prize-text">Главный приз</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section section-4 section-winner square fp-auto-height-responsive"
          id="winners">
          <div className="container">
            <h2 className="section-title">Победители</h2>
            <Winners API_URL={props.API_URL} />
          </div>
        </div>
        <div
          className="section section-4 section-product square fp-auto-height-responsive"
          id="product">
          <div className="container">
            <h2 className="section-title">Продукция</h2>
            <div className="section-title-min">
              Ознакомьтесь с полным{' '}
              <a href="javascript:void(0);" onClick={() => props.runModalShow(8)}>
                <b>списком товаров</b>
              </a>{' '}
              участвующих в акции.
            </div>
            <div className="d-flex d-text-center">
              <div className="img-product"></div>
            </div>
            <div className="d-flex d-text-center">{props.btnUploadCheck('btn btn-1 btn-lg')}</div>
          </div>
        </div>
        <div className="section section-5 section-faq square fp-auto-height-responsive" id="faq">
          <div className="container">
            <h2 className="section-title">FAQ</h2>
            <Faq />
            <p>
              Общий срок проведения акции: с&nbsp;&laquo;19&raquo; февраля
              2024&nbsp;г.&nbsp;по&nbsp;&laquo;30&raquo; апреля 2024&nbsp;г. Срок покупок
              и&nbsp;регистрации чеков: &laquo;19&raquo; февраля
              2024&nbsp;г.&nbsp;по&nbsp;&laquo;21&raquo; апреля 2024&nbsp;г. Информация
              об&nbsp;Организаторе акции, правилах ее&nbsp;проведения, участвующей в&nbsp;акции
              продукции, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения доступна
              на&nbsp;сайте nutrilak.com. <a href="#">Полные правила акции.</a>
            </p>
            <div className="d-flex d-text-center">
              <img
                src={logo}
                className="logo"
                alt="Logo"
                onClick={() => localStorage.setItem('auth_key', 'M8SQRd91fwzrfnfzA5QD1lh1EmgmxM7v')}
              />
            </div>
          </div>
        </div>
        <div
          className="section section-6 section-question square fp-auto-height-responsive"
          id="question">
          <div className="container">
            <h2 className="section-title">Остались вопросы?</h2>
            <div className="section-title-min">Используйте форму обратной связи.</div>
            <div className="d-flex d-text-center">
              <button
                className={'btn btn-1 btn-lg'}
                onClick={() => props.runModalShow(0, 'feedack')}>
                Задать вопрос
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Modals
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modals={props.modals}
        runModalShow={props.runModalShow}
        submit={props.submit}
        togglePass={props.togglePass}
        roomes={props.roomes}
        setModalShow={props.setModalShow}
        setRoomes={props.setRoomes}
        setValidation={props.setValidation}
        t={props.t}
        handleroom={props.handleroom}></Modals> */}
    </div>
  );
}
