import React, { useState, useEffect } from 'react';
import Input from './Input';
import Modal from './Modal';
export default function App(props) {
  return (
    <div className="modals__list">
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[0]}
        key={props.modals[0].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('feedack');
            }}
            className="btn btn-3 btn-md">
            Зарегистрироваться
          </button>
        </div>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[1]}
        key={props.modals[1].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('registr');
            }}
            className="btn btn-3 btn-md">
            Зарегистрироваться
          </button>
          <button
            onClick={() => {
              props.runModalShow(3, 'login');
            }}
            className="btn btn-2 btn-md">
            Вход
          </button>
        </div>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[2]}
        key={props.modals[2].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <p>Ваше сообщение успешно отправлено. Мы ответим Вам в ближайшие 72 часа.</p>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[3]}
        key={props.modals[3].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('login');
            }}
            className="btn btn-3 btn-md">
            Войти
          </button>
          <button
            onClick={() => {
              props.runModalShow(1, 'registr');
            }}
            className="btn btn-2 btn-md">
            Регистрация
          </button>
        </div>
        <a
          href="#"
          className="forgonLoginLink"
          onClick={() => {
            props.runModalShow(5, 'forgonLogin');
          }}>
          Забыли пароль?
        </a>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[5]}
        key={props.modals[5].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('lofin');
            }}
            className="btn btn-1 btn-md">
            Отправить
          </button>
        </div>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[6]}
        key={props.modals[6].id}>
        <ul>
          <li>Детский Мир (розничные магазины и интернет-магазин)</li>
          <li>Магнит (розничные магазины и интернет-магазин)</li>
          <li>Лента (розничные магазины и интернет-магазин)</li>
          <li>Пятерочка (розничные магазины и интернет-магазин)</li>
          <li>Гиперглобус (розничные магазины и интернет-магазин)</li>
          <li>Озон</li>
          <li>Вайлдбериз</li>
        </ul>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[7]}
        key={props.modals[7].id}>
        <img src={localStorage.getItem('checkImg')} />
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[8]}
        key={props.modals[8].id}>
        <p>В акции принимает участие следующая продукция:</p>
        <ul>
          <li>Nutrilak Premium 1 300 г</li>
          <li>Nutrilak Premium 2 300 г</li>
          <li>Nutrilak Premium 3 300 г</li>
          <li>Nutrilak Premium 1 600 г</li>
          <li>Nutrilak Premium 2 600 г</li>
          <li>Nutrilak Premium 3 600 г</li>
          <li>Nutrilak Premium 4 600 г</li>
          <li>Nutrilak Premium 1 1050 г</li>
          <li>Nutrilak Premium 2 1050 г</li>
          <li>Nutrilak Premium 3 900 г</li>
          <li>Nutrilak Premium 4 900 г</li>
          <li>Nutrilak Premium Caesarea 350 г</li>
          <li>Nutrilak Premium Антирефлюксный 350 г</li>
          <li>Nutrilak Premium Безлактозный 350 г</li>
          <li>Nutrilak Premium Безлактозный 600 г</li>
          <li>Nutrilak Premium Кисломолочный 350 г</li>
          <li>Nutrilak Premium Комфорт 350 г</li>
          <li>Nutrilak Premium Комфорт 600 г</li>
          <li>Nutrilak Premium Пептиди 350 г</li>
          <li>Nutrilak Premium Гипоаллергенный 350 г</li>
          <li>Nutrilak Premium Гипоаллергенный 600 г</li>
          <li>Nutrilak Premium Соя 350 г</li>
          <li>Nutrilak Premium Пре 350 г</li>
          <li>Nutrilak Premium Амино 400 г</li>
        </ul>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[11]}
        key={props.modals[11].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('updateProfile');
            }}
            className="btn btn-3 btn-md">
            Изменить
          </button>
        </div>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[12]}
        key={props.modals[12].id}>
        <Input
          rooms={props.roomes}
          setRooms={props.setRoomes}
          setValidation={props.setValidation}
          togglePass={props.togglePass}
          handleroom={props.handleroom}
        />
        <div className="modal__box-content-btn">
          <button
            onClick={() => {
              props.submit('updateProfile');
            }}
            className="btn btn-3 btn-md">
            Изменить
          </button>
          <button
            onClick={() => {
              props.runModalShow(11, 'newPass');
            }}
            className="btn btn-2 btn-md">
            Изменить пароль
          </button>
        </div>
      </Modal>
      <Modal
        modalShow={props.modalShow}
        modalClose={props.modalClose}
        modalData={props.modals[13]}
        key={props.modals[13].id}>
        <h3>Данные обновлены</h3>
      </Modal>
    </div>
  );
}
